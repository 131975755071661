window.addEventListener("DOMContentLoaded", function () {

    if(document.querySelector('.mobile-toolbar .item.connect')) {

        document.querySelector('.mobile-toolbar .item.connect').onclick = () => {

            const height = document.querySelector('.mobile-toolbar .item.connect div.toggle-options').offsetHeight;
            document.querySelector('.mobile-toolbar .item.connect div.toggle-options').style.top = `-${height + 11}px`;
        };
    }
});

