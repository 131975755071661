window.addEventListener("DOMContentLoaded", function () {
    if ( document.querySelector('.items-block--content') ) {
        if ( document.querySelector('.items-block--content').children ) {
            items = document.querySelector('.items-block--content').children;
            const numberOfColumns = parseInt( document.querySelector('.items-block--content').style.gridTemplateColumns.slice(7, 8) );
            items = [].slice.call( items );
            items.map( ( item, index ) =>  {
                let hasImage;
                if ( item.querySelector('img') ) {
                    hasImage = item.querySelector('img').getAttribute('src');
                }

                if( ! hasImage && item.querySelector('figure') ) {
                    item.querySelector('figure').style.display = 'none';
                }
            } );

            const seeMore = document.querySelector('.see-more');

            if ( numberOfColumns >= items.length ) {
                seeMore.style.display = 'none';

                return;
            }
            items = items.slice( numberOfColumns );

            items.forEach( item => {
                item.style.display = 'none';
            } );

            
            const showMoreItems = () => {
                items.forEach( item => {
                    if ( item.style.display == 'flex' ) {
                        item.style.display = 'none';
                    } else {
                        item.style.display = 'flex';
                    }
                } );

                if ( document.querySelector('.items-block .hide').style.display == 'none' ) {
                    document.querySelector('.items-block .hide').style.display = 'block';
                    document.querySelector('.items-block .show').style.display = 'none';
                } else {
                    document.querySelector('.items-block .show').style.display = 'block';
                    document.querySelector('.items-block .hide').style.display = 'none';
                }
            }
            
            seeMore.onclick = showMoreItems;
        }

    }
});

