window.addEventListener('DOMContentLoaded', function () {
    if (!document.querySelector('.posts-list')) return;

    let canBeLoaded = true;
    let bottomOffset = 1200;
    let firstLoad = true;
    let selectedCategoryCount = -1;

    const postList = document.querySelector('.ajax-posts .posts-list');
    const postsContainer = postList.querySelector('.posts-list--content');
    const initialTotal = document.querySelector('.total > strong').innerText
    const totalElement = document.querySelector('.total > strong');
    const counterElement = document.querySelector('.actual-number > strong');

    if (!postList.hasChildNodes()) {
        return;
    }

    postsContainer.addEventListener('DOMSubtreeModified', () => {
        counterElement.innerText = postsContainer.childElementCount;
    })

    const data = {
        'action': 'loadmore',
        'query': stop_the_wall_ajax_params.posts,
        'page': stop_the_wall_ajax_params.current_page,
    };

    document.querySelector('select#sort-options').addEventListener('change', () => {
        const processedArgs = JSON.parse(stop_the_wall_ajax_params.posts);

        const selectedOption = document.querySelector('select#sort-options').selectedOptions[0].value
        if (selectedOption == 'date-oldest') {
            processedArgs['orderby'] = 'date';
            processedArgs['order'] = 'ASC';
        } else {
            processedArgs['orderby'] = 'date';
            processedArgs['order'] = 'DESC';
        }

        stop_the_wall_ajax_params.posts = JSON.stringify(processedArgs);
        stop_the_wall_ajax_params.current_page = 0;

        postsContainer.innerHTML = '';

        postList.querySelector('.loading-posts').style.display = 'flex';
        buildPosts(stop_the_wall_ajax_params, {
            'action': 'loadmore',
            'query': stop_the_wall_ajax_params.posts,
            'page': stop_the_wall_ajax_params.current_page,
        }, postList, false);
    });

    window.addEventListener('load', () => {

        if ((window.scrollY > (document.body.clientHeight - bottomOffset) && canBeLoaded == true) || firstLoad) {
            const processedArgs = JSON.parse(stop_the_wall_ajax_params.posts);
            if (firstLoad) {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                let term = urlParams.get('term');

                if (!term) {
                    term = -1;
                }

                const categoryId = parseInt(term);
                const processedArgs = JSON.parse(stop_the_wall_ajax_params.posts);
                const element = document.querySelector(`.ajax-posts .filtro-posts .categories > a[data-id="${term}"]`);
                const itensCount = parseInt(element.getAttribute('data-count'));
                const taxonomy = element.getAttribute('data-taxonomy');

                selectedCategoryCount = categoryId > 0 ? itensCount : -1;

                if (categoryId > 0) {
                    if (taxonomy != 'category') {
                        var args = [{
                            'taxonomy': taxonomy,
                            'terms': categoryId
                        }];
                        processedArgs['tax_query'] = args;
                    } else {
                        processedArgs['category__in'] = categoryId;
                    }
                } else {
                    /* Remove tax_query - because posts can be resources but without resource_type */
                    processedArgs['tax_query'] = undefined;
                }

                stop_the_wall_ajax_params.posts = JSON.stringify(processedArgs);
                stop_the_wall_ajax_params.current_page = 0;

                totalElement.innerText = selectedCategoryCount >= 0 ? selectedCategoryCount : initialTotal;

                document.querySelectorAll('.ajax-posts .filtro-posts .categories > a').forEach(_element => {
                    _element.classList.remove('active');
                });
                element.classList.add('active');

                postsContainer.innerHTML = '';

                postList.querySelector('.loading-posts').style.display = 'flex';
                buildPosts(stop_the_wall_ajax_params, {
                    'action': 'loadmore',
                    'query': stop_the_wall_ajax_params.posts,
                    'page': stop_the_wall_ajax_params.current_page,
                }, postList, false);

                firstLoad = false;
            } else {
                if (processedArgs.posts_per_page * stop_the_wall_ajax_params.current_page < selectedCategoryCount || selectedCategoryCount < 0) {
                    postList.querySelector('.loading-posts').style.display = 'flex';
                    buildPosts(stop_the_wall_ajax_params, {
                        'action': 'loadmore',
                        'query': stop_the_wall_ajax_params.posts,
                        'page': stop_the_wall_ajax_params.current_page,
                    }, postList);
                }
            }
        }
    })

    window.addEventListener('scroll', () => {
        if ((window.scrollY > (document.body.clientHeight - bottomOffset) && canBeLoaded == true) || firstLoad) {
            const processedArgs = JSON.parse(stop_the_wall_ajax_params.posts);
            firstLoad = false;

            if (processedArgs.posts_per_page * stop_the_wall_ajax_params.current_page < selectedCategoryCount || selectedCategoryCount < 0) {
                postList.querySelector('.loading-posts').style.display = 'flex';

                buildPosts(stop_the_wall_ajax_params, {
                    'action': 'loadmore',
                    'query': stop_the_wall_ajax_params.posts,
                    'page': stop_the_wall_ajax_params.current_page,
                }, postList);
            }
        }
    })

    document.querySelectorAll('.ajax-posts .filtro-posts .categories > a').forEach(element => {
        element.onclick = () => {
            const categoryId = parseInt(element.getAttribute('data-id'));
            const processedArgs = JSON.parse(stop_the_wall_ajax_params.posts);
            const itensCount = parseInt(element.getAttribute('data-count'));
            const taxonomy = element.getAttribute('data-taxonomy');

            selectedCategoryCount = categoryId > 0 ? itensCount : -1;

            if (categoryId > 0) {
                if (taxonomy != 'category') {
                    var args = [{
                        'taxonomy': taxonomy,
                        'terms': categoryId
                    }];
                    processedArgs['tax_query'] = args;
                } else {
                    processedArgs['category__in'] = categoryId;
                }
            } else {
                /* Remove tax_query - because posts can be resources but without resource_type */
                processedArgs['tax_query'] = undefined;
            }

            stop_the_wall_ajax_params.posts = JSON.stringify(processedArgs);
            stop_the_wall_ajax_params.current_page = 0;

            totalElement.innerText = selectedCategoryCount >= 0 ? selectedCategoryCount : initialTotal;

            document.querySelectorAll('.ajax-posts .filtro-posts .categories > a').forEach(_element => {
                _element.classList.remove('active');
            });
            element.classList.add('active');

            postsContainer.innerHTML = '';

            postList.querySelector('.loading-posts').style.display = 'flex';

            buildPosts(stop_the_wall_ajax_params, {
                'action': 'loadmore',
                'query': stop_the_wall_ajax_params.posts,
                'page': stop_the_wall_ajax_params.current_page,
            }, postList, false);
        }
    });

    function buildPosts(params, data, postList, incrementContent = true) {
        const postsContainer = postList.querySelector('.posts-list--content');
        data.language = params.language;

        jQuery.ajax({
            url: params.ajaxurl,
            data: data,
            type: 'POST',
            beforeSend: function (xhr) {
                if (params.current_page + 1 >= params.max_page) {
                    postList.querySelector('.loading-posts').style.display = 'none';
                }

                canBeLoaded = false;
                postList.querySelector('.loading-posts').classList.add('active');
            },
            success: function (data) {
                if (data) {

                    if (incrementContent) {
                        postsContainer.innerHTML += `${data}`;
                    } else {
                        postsContainer.innerHTML = `${data}`;
                    }

                    document.location.hash = 'page:' + params.current_page;
                    params.current_page++;

                    canBeLoaded = true;

                    postList.querySelector('.loading-posts').classList.remove('active');

                    if (parseInt(document.querySelector('.posts-quantity .actual-number strong').innerText) == parseInt(document.querySelector('.posts-quantity .total strong').innerText)) {
                        postList.querySelector('.loading-posts').style.display = 'none';
                    }
                }
            },

        });
    }
});