(function($){
    if(document.querySelector('.pictures-slider .pictures .itens')) {   
        jQuery(document).ready(function(){
            const slickOptions = {
                focusOnSelect: true,
                dots: false,
                infinite: true,
                slidesToShow: 1,
                adaptiveHeight: true,
                arrows: true,
                //prevArrow: '<button type="button" data-role="none" class="single-item-collection--attachments-prev" aria-label="Previous" role="button" style="display: block;"></button>',
                //nextArrow: '<button type="button" data-role="none" class="single-item-collection--attachments-next" aria-label="Next" role="button" style="display: block;"></button>',
                prevArrow: jQuery('.single-item-collection--attachments-prev'),
                nextArrow: jQuery('.single-item-collection--attachments-next'),
                //prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button" style="display: block;">Previous</button>',
                //nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button" style="display: block;">Next</button>'
            
            };
    
            jQuery('.pictures-slider .pictures .itens').slick(slickOptions);
        
        
            jQuery('.image-cut img, button.expand-icon').click(function() {
                jQuery(this).closest('.pictures').addClass('active');
                jQuery(this).closest('.pictures').css('height', jQuery(window).height() );
                jQuery('.slider').css('height', jQuery(window).height() );
                
            });
        
            jQuery('.close-button').click(function() {
                jQuery(this).closest('.pictures').removeClass('active');
                jQuery('.slider').css('height', 'auto' );
                jQuery('.pictures').css('height', 'auto' );
            });
    
            const slick = jQuery('.pictures-slider .pictures .itens');
        
            for (fullscreenButton of document.querySelectorAll('.pictures-slider .expand-icon')) {
                fullscreenButton.onclick = () => {
                    slick.slick('slickGoTo', slick.slick('slickCurrentSlide'), true);
                }
            };
            document.querySelector('.pictures-slider .close-button').onclick = () => {
                slick.slick('slickGoTo', slick.slick('slickCurrentSlide'), true);
            }
    
            if (document.querySelectorAll('.slick-slide:not(.slick-cloned)')) {
                const numberOfPhotos = document.querySelectorAll('.slick-slide:not(.slick-cloned)').length;
    
                for(let photo of document.querySelectorAll('.slick-slide:not(.slick-cloned)')) {
                    const counter = parseInt(photo.attributes['data-slick-index'].value) + 1;
    
                    photo.children[0].children[0].children[1].children[0].children[0].textContent = `${counter} / ${ numberOfPhotos }`;
                }
            }
        })    
    }
})(jQuery);