import "./font-size";

window.addEventListener("DOMContentLoaded", function () {
    const header = document.querySelector(".header");

    // Sticky header events
    const stickyEvents = [
        { event: 'scroll', target: document}, 
        { event: 'resize', target: window }
    ];

    const scrollCallback = function () {
        const windowScrollTop = document.querySelector('html').scrollTop;

        if(screen.width >= 1024) {
            if(windowScrollTop > 30) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        } else {
            header.classList.add('sticky');
        }
    };
    const offsetLeft = header.querySelector('.header--main .hamburger-menu').offsetLeft;
    const offsetWidth = header.querySelector('.header--main .hamburger-menu').offsetWidth;
    const offsetRight = window.innerWidth - offsetLeft + 35 - offsetWidth;
    
    if(!header.classList.contains('petition')) {
        scrollCallback();
        stickyEvents.forEach(( { event, target } ) => target.addEventListener(event, scrollCallback));

    }

    header.style.setProperty(
        "--padding-left",
        offsetLeft + "px"
    );

    header.style.setProperty(
        "--padding-right",
        offsetRight + "px"
    );

    // Open primary menu hamburguer
    header.querySelectorAll('.hamburger-menu').forEach(hamburguerIcon => hamburguerIcon.addEventListener('click', function() {
        header.querySelector('.header--content').classList.toggle('active');
        document.querySelector('.header--main .hamburger-menu i').classList.toggle('fa-times');
        document.querySelector('.header--sticky .hamburger-menu i').classList.toggle('fa-times');
    }));

    // Close primary menu on outside click
    document.addEventListener('click', function(event) {
        let isClickInsideElement = Array.from(header.querySelectorAll('.header--content, .hamburger-menu')).some(element => element.contains(event.target));

        if (!isClickInsideElement) {
            header.querySelector('.header--content').classList.remove('active');
            header.querySelectorAll('.hamburger-menu').forEach(hamburguerMenu => {
                if (document.querySelector('.header--main .hamburger-menu i')){
                    document.querySelector('.header--main .hamburger-menu i').classList.remove('fa-times');
                }
                document.querySelector('.header--sticky .hamburger-menu i').classList.remove('fa-times');
            })
        }
    })

    // Open toggable search area (header)
    header.querySelectorAll('.search').forEach(search => {
        search.addEventListener('click', function() {
            header.querySelector('.header--search').classList.toggle('active');
            document.querySelector('.header--main .search i').classList.toggle('fa-times');
            document.querySelector('.header--sticky .search i').classList.toggle('fa-times');
        })
    })

    // Close toggable search area (header) on outside click
    document.addEventListener('click', function(event) {
        let isClickInsideElement = Array.from(header.querySelectorAll('.header--search, button.search')).some(element => element.contains(event.target));

        if (!isClickInsideElement) {
            header.querySelector('.header--search').classList.remove('active');
            header.querySelectorAll('button.search').forEach(searchButton => {
                searchButton.querySelector('i').classList.remove('fa-times');
            })
        }
    })

    // Menu itens hover - class toggle
    header.querySelectorAll('.main-menu > li').forEach(menuItem => {
        if(window.outerWidth >= 1025) {
            menuItem.addEventListener('mouseover', function() {
                header.querySelectorAll('.main-menu > li').forEach(item => item.classList.remove('active'));
                this.classList.add('active');
            })
        }

        if(window.outerWidth <= 1024) {
            menuItem.addEventListener('click', function() {
                if(!this.classList.contains('active')) {
                    this.classList.add('active');
                } else {
                    this.classList.remove('active');
                }
    
                header.querySelectorAll('.main-menu > li').forEach(item => {
                    if(item != this) {
                        item.classList.remove('active')
                    }
                });
            })
        }
    })



    // Legacy scripts down bellow
    jQuery('button[action="toggle-options"]').click(function () {
        jQuery(this.parentNode.querySelector(".toggle-options")).toggleClass('active');
    });

    document.addEventListener('click', function(event) {
        var isClickInsideElement = document.querySelector('button[action="toggle-options"]').contains(event.target);
        if (!isClickInsideElement && !['increase-size', 'decrease-size'].includes(event.target.getAttribute('action'))) {
            if(document.querySelector(".toggle-options").classList.contains('active')) {
                document.querySelector(".toggle-options").classList.remove('active')
            }
        }
    });

   
    jQuery('button[action="language-options"]').click(function () {
        jQuery(this.parentNode.querySelector(".toggle-language-options")).toggleClass(
            "active"
        );
    });

    const shareData = {
        title: document.title,
        text: "",
        url: document.location.href,
    };

    const btn = document.querySelector('button[action="share-navigator"]');
    const resultPara = document.querySelector("body");

    if(document.location.protocol != 'http:') {
        btn.addEventListener("click", () => {
            try {
                navigator.share(shareData);
            } catch (err) {
                resultPara.textContent = "Error: " + err;
            }
        });
    } else {
        console.log("Native share is not allowed over HTTP protocol.")
    }

});
